export const Team = ({
    state: () => ({
        team: [
            {mask: 'mask-1', img: 'Dmitriy.jpg', name: 'Дмитрий', position: 'Chief executive officer, основатель'},
            {mask: 'mask-2', img: "Sophia.jpg", name: "Софья", position: "Chief commercial officer, партнер"},
            {mask: 'mask-3', img: 'Sergey.jpg', name: 'Сергей', position: 'Project manager, партнер'},
            {mask: 'mask-1', img: 'Vlad.jpg', name: 'Влад', position: 'Chief technical officer'},
            {mask: 'mask-3', img: 'Dasha_2.jpg', name: 'Даша', position: 'Head of QA'},
            {mask: 'mask-4', img: 'maks_1.jpg', name: 'Макс', position: 'Head of Support'},
            {mask: 'mask-1', img: 'Tanya.jpg', name: 'Таня', position: 'Сhief financial officer'},
            {mask: 'mask-6', img: 'Pasha.jpg', name: 'Паша', position: 'Chef mediation officer, партнер'},
            {mask: 'mask-7', img: 'Marina.jpg', name: 'Марина', position: 'Head of sales department'},
            {mask: 'mask-4', img: 'Alexsandr.jpg', name: 'Саша', position: 'Head of enterprise sales'},
            //new
            {mask: 'mask-2', img: 'Misha_3.jpg', name: 'Михаил', position: 'Партнер'},
            //new
            {mask: 'mask-4', img: 'Misha.jpg', name: 'Миша', position: 'Senior technical support and implementation'},
            {mask: 'mask-4', img: 'Nastya-support.jpg', name: 'Настя', position: 'Technical support engineer'},
            {mask: 'mask-5', img: 'Leonid.jpg', name: 'Леонид', position: 'Technical support engineer'},
            {mask: 'mask-4', img: 'Ivan.jpg', name: 'Иван', position: 'Customer development and support'},
            //new
            {mask: 'mask-3', img: 'Peter.jpg', name: 'Петя', position: 'Junior technical support engineer'},
            {mask: 'mask-3', img: 'Stepan.jpg', name: 'Стёпа', position: 'Technical support trainee'},
            //new
            // vacancy
            {mask: 'mask-6', img: 'mr-Smith.png', name: 'Wanted', position: '<a href="/career/vacancy/technical-support-2024" class="t-c-g">Junior technical support engineer</a>'},
            // vacancy
            {mask: 'mask-3', img: 'Olya.jpg', name: 'Оля', position: 'Heritage projects support'},
            {mask: 'mask-3', img: 'Katya.jpg', name: 'Катя', position: 'Head of Data Labeling & QA'},
            {mask: 'mask-2', img: 'Diana.jpg', name: 'Диана', position: 'QA'},
            {mask: 'mask-1', img: 'Nastya-cyp.jpg', name: 'Настя', position: 'Cybercarpenter'},
            {mask: 'mask-6', img: 'Oleg.jpg', name: 'Олег', position: 'Computer vision developer'},
            {mask: 'mask-5', img: 'Vanya.jpg', name: 'Ваня', position: 'Senior technical support and implementation'},
            {mask: 'mask-2', img: 'Misha_2.jpg', name: 'Миша', position: 'Head of embedded systems development & backend developer'},
            {mask: 'mask-4', img: 'Roma.jpg', name: 'Рома', position: 'Backend developer'},
            {mask: 'mask-5', img: 'Kirill.jpg', name: 'Кирилл', position: 'Junior backend developer'},
            {mask: 'mask-7', img: 'Yana.jpg', name: 'Яна', position: 'Frontend developer'},
            {mask: 'mask-4', img: 'Vova.jpg', name: 'Вова', position: 'Frontend developer'},
            {mask: 'mask-2', img: 'Denis.jpg', name: 'Денис', position: 'Design & visualization'},
            {mask: 'mask-4', img: 'Nadya.jpg', name: 'Надя', position: 'Tech writer & localization '},
            {mask: 'mask-6', img: 'Masha-2.jpg', name: 'Мария', position: 'Digital artist'},
            {mask: 'mask-5', img: 'Sophia-dm.jpg', name: 'Соня', position: 'Digital marketing'},
            {mask: 'mask-5', img: 'Masha.jpg', name: 'Маша', position: 'HR Rotor Product owner,<br> HR business partner'},
            {mask: 'mask-6', img: 'Liza.jpg', name: 'Лиза', position: 'HR Rotor project  manager, Customer care'},
            // new
            // {mask: 'mask-1', img: '#', name: 'Егор', position: 'Assistant accountant'}
        ],
    }),
    getters: {
        getTeam(state) {
            return state.team
        }
    }
})
